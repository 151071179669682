import React from 'react';
import parse, {
    HTMLReactParserOptions,
    Element,
    domToReact,
    DOMNode,
} from 'html-react-parser';

export const parseHtmlString = (
    content: string,
    options?: {
        removeStrongTag?: boolean;
    }
) => {
    if (typeof content == 'string') {
        let parserOptions: HTMLReactParserOptions;

        if (options?.removeStrongTag) {
            parserOptions = {
                replace({type, name, children}: Element) {
                    if (type === 'tag' && name === 'strong') {
                        return (
                            <>
                                {domToReact(
                                    children as DOMNode[],
                                    parserOptions
                                )}
                            </>
                        );
                    }

                    return;
                },
            };
        }

        return parse(content, parserOptions);
    }

    return content || '';
};
