import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getUpdates} from 'shared/components/Updates/helper/getUpdates';
import {Update} from 'shared/components/Updates/Update';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {Update as UpdateI} from 'shared/components/Updates/entity/Update';
import {checkShowUpdateToUser} from 'shared/components/Updates/helper/checkShowUpdateToUser';
import {checkTutorialIsDisabled} from 'shared/components/Updates/helper/checkTutorialIsDisabled';
import {useTutorialMutation} from 'shared/components/Updates/store/updateApi';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    selectShowUpdateMessage,
    selectUpdateMessageShown,
    showUpdateMessageSet,
} from 'store/customer/appSlice';

export const Updates = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const {data: user} = useGetUserQuery();
    const [visible, setVisible] = React.useState<boolean[]>([]);
    const [updates, setUpdates] = useState<UpdateI[]>([]);
    const [setRoomAssistantTutorial] = useTutorialMutation();
    const isOldUpdatePopupShown = useAppSelector(selectUpdateMessageShown);
    const showMessage = useAppSelector(selectShowUpdateMessage);

    const setShow = useCallback(
        (currentIndex: number) => (showAgain: boolean, key: string) => {
            if (!showAgain) {
                void setRoomAssistantTutorial({[key]: false});
            }
            dispatch(showUpdateMessageSet(''));

            setVisible((visible) =>
                visible.map((value, index) => {
                    if (index == currentIndex + 1) return true;

                    return false;
                })
            );
        },
        []
    );

    useEffect(() => {
        if (!isOldUpdatePopupShown) {
            setVisible(
                Array.from({length: updates.length}, (_, index) => index == 0)
            );
        }
    }, [updates, isOldUpdatePopupShown]);

    useEffect(() => {
        if (user) {
            const updates = getUpdates();
            const {pathname, search} = location;

            const updatesForPage = updates
                .filter((update) => {
                    if (showMessage) {
                        return update.tutorialKey == showMessage;
                    }

                    return true;
                })
                .filter((update) => showMessage || !update.isDisabled)
                .filter(
                    (update) =>
                        showMessage ||
                        checkShowUpdateToUser(update.tutorialKey, user)
                )
                .filter(
                    (update) =>
                        showMessage ||
                        checkTutorialIsDisabled(update.tutorialKey, user)
                )
                .filter((update) => {
                    if (showMessage) return true;
                    const {page} = update;
                    const pathCheck = pathname + search;
                    // NOTE: Not checking job/room params for now
                    // as it is not required. Add that as it becomes
                    // necessary in the future.

                    return page.includes(pathCheck);
                });

            setUpdates(updatesForPage);
        }
    }, [location, showMessage]);

    if (updates.length > 0) {
        return updates.map((update, index) => (
            <Update
                key={update.id}
                update={update}
                show={visible[Number(index)]}
                setShow={setShow(index)}
            />
        ));
    }

    return null;
};
