import React, {lazy, ReactNode, Suspense} from 'react';
import {RouteObject} from 'react-router-dom';
import Layout from 'shared/components/Layout';
import {Dashboard, Job, Jobs, JobDashboard} from 'components';
import {Favourites} from 'components/customer/Favourites/Favourites';
import {Cart, FourZeroFour, JobRoute} from 'shared';
import {Loader} from 'shared/helpers';

const Settings = lazy(() => import('components/customer/Settings/Settings'));
const Room = lazy(() => import('components/customer/Room/Room'));
const BenchtopDesigner = lazy(
    () => import('components/customer/BenchtopDesigner/BenchtopDesigner')
);
const BenchtopModule = lazy(() => import('components/customer/BTM'));
const LayoutDesigner = lazy(
    () => import('components/customer/LayoutDesigner/LayoutDesigner')
);
const ProductWrapper = lazy(
    () => import('components/customer/Product/Products')
);
const KDMaxWrapper = lazy(
    () => import('components/customer/KDMax/KDMaxImport')
);
const QFPSwitch = lazy(() => import('components/customer/QFPRedux/QFPSwitch'));
const Hardware = lazy(() => import('components/customer/Hardware/Hardware'));

const SuspenseLoader = ({children}: {children: ReactNode}) => (
    <Suspense fallback={<Loader loader={true} />}>{children}</Suspense>
);

export const routes: RouteObject[] = [
    {
        path: 'job/:jobId',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <Job />
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/hardware',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <Hardware />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room/:roomId',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <Room />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room/:roomId/benchtop-designer',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <BenchtopDesigner />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room/:roomId/layout',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <LayoutDesigner />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room/:roomId/product',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <ProductWrapper />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room/:roomId/quick-flat-product',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <QFPSwitch />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room/:roomId/kdmax-import',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <KDMaxWrapper />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room/:roomId/dashboard',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <JobDashboard />
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/dashboard',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <JobDashboard />
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room/:roomId/favourites',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <Favourites />
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/cart',
        element: <JobRoute />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <Cart />
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job',
        element: <JobRoute />,
        children: [
            {
                path: '',
                element: (
                    <Layout>
                        <Job />
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/room',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <Room />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'job/:jobId/benchtop-module',
        element: <JobRoute checkProcessingStatus={true} />,
        children: [
            {
                path: '*',
                element: (
                    <Layout withSidebar>
                        <SuspenseLoader>
                            <BenchtopModule />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        path: 'jobs/:status',
        element: (
            <Layout>
                <Jobs />
            </Layout>
        ),
    },
    {
        path: 'jobs',
        element: (
            <Layout>
                <Jobs />
            </Layout>
        ),
    },
    {
        path: 'account-settings',
        children: [
            {
                path: '*',
                element: (
                    <Layout>
                        <SuspenseLoader>
                            <Settings />
                        </SuspenseLoader>
                    </Layout>
                ),
            },
        ],
    },
    {
        index: true,
        element: (
            <Layout>
                <Dashboard />
            </Layout>
        ),
    },
    {
        path: '*',
        element: (
            <Layout>
                <FourZeroFour />
            </Layout>
        ),
    },
];
