import {EndpointBuilder} from '@reduxjs/toolkit/src/query/endpointDefinitions';
import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import {keyBy, mapValues} from 'lodash';
import {AppApi, apiReducerPath} from 'store/customer/api';
import {Branding} from 'store/customer/brandingSlice';
import {Colours} from 'theme';

interface BrandingColor {
    setting_name: string;
    colour: string;
}

interface BrandingResponse {
    available: boolean;
    branding_manufacturer_name: string;
    application_logo: string;
    branding_manufacturer_favicon: string;
    login_logo: string;
    background_image: string;
    font_normal: string;
    font_bold: string;
    font_italic: string;
    is_custom_font: boolean;
    status: number;
    permit_customer_self_registration: boolean;
    allow_direct_login: boolean;
    parent_application_name: string;
    parent_application_url: string;
    branding_colour: BrandingColor[];
}

export interface BrandingInformation {
    available: boolean;
    branding?: Branding;
    colours?: Colours;
}

export const COLOR_MAP: {[key: string]: string} = {
    primary: 'primary_colour',
    secondary: 'secondary_colour',
    buttonText: 'button_text_colour',
    headerColour: 'header_colour',
    tableHover: 'table_hover',
    tableRowPrimary: 'table_row_primary',
    tableRowSecondary: 'table_row_secondary',
    tableTextColor: 'table_text_colour',
    menuPrimaryColor: 'menu_primary_colour',
    menuSecondaryColor: 'menu_secondary_colour',
    primaryIconColor: 'primary_icon_colour',
    secondaryIconColor: 'secondary_icon_colour',
    navigationTextColor: 'navigation_text_colour',
    cartIconColor: 'cart_icon_colour',
    headerIconColor: 'header_icon_colour',
    headerNavigationColor: 'header_navigation_text_colour',
    loginHeaderColor: 'login_header_colour',
    mobileMenuColor: 'mobile_menu_colour',
};

const arrayToObject = (brandingColors: BrandingColor[]) => {
    return mapValues(keyBy(brandingColors, 'setting_name'), 'colour');
};

const getValue = (brandingColors: {[key: string]: string}, key: string) => {
    if (brandingColors.hasOwnProperty(key)) {
        return brandingColors[String(key)];
    }

    return null;
};

const getColours = (brandingColours: BrandingColor[]) => {
    const colorDictionary = arrayToObject(brandingColours);
    const colours: Colours = {};

    for (const key in COLOR_MAP) {
        // checking if the key is a direct property of COLOR_MAP
        if (Object.prototype.hasOwnProperty.call(COLOR_MAP, key)) {
            const dictionaryKey = COLOR_MAP[String(key)];

            const value = getValue(colorDictionary, dictionaryKey);

            if (value != null) {
                const colorKey = key as keyof Colours;
                // disabling generic object injection sink below as key only comes
                // from map above not from user's input
                // eslint-disable-next-line security/detect-object-injection
                colours[colorKey] = {
                    main: value,
                };
            }
        }
    }

    return colours;
};

const getBranding = (data: BrandingResponse) => {
    const branding: Branding = {
        font_normal: data.font_normal,
        is_custom_font: data.is_custom_font,
        manufacturer_active: data.status == 0,
        permit_customer_self_registration:
            data.permit_customer_self_registration,
        allow_direct_login: data.allow_direct_login,
        parent_application_name: data.parent_application_name,
        parent_application_url: data.parent_application_url,
    };

    // setting value of bold and italic font to empty string
    // if the value received form backend is non stringy.
    if (typeof data.font_bold === 'string') {
        branding.font_bold = data.font_bold;
    } else {
        branding.font_bold = '';
    }

    if (typeof data.font_italic === 'string') {
        branding.font_italic = data.font_italic;
    } else {
        branding.font_italic = '';
    }

    if (data.background_image !== null && data.background_image != '') {
        branding.background_image = data.background_image;
    }

    if (data.login_logo !== null && data.login_logo != '') {
        branding.login_logo = data.login_logo;
    }

    if (data.application_logo !== null && data.application_logo != '') {
        branding.application_logo = data.application_logo;
    }

    if (
        data.branding_manufacturer_favicon !== null &&
        data.branding_manufacturer_favicon != ''
    ) {
        branding.branding_manufacturer_favicon =
            data.branding_manufacturer_favicon;
    }

    if (
        data.branding_manufacturer_name !== null &&
        data.branding_manufacturer_name != ''
    ) {
        branding.branding_manufacturer_name = data.branding_manufacturer_name;
    }

    return branding;
};

export const getEndpoints = <T extends string>() => ({
    endpoints: (
        builder: EndpointBuilder<
            BaseQueryFn<
                string | FetchArgs,
                unknown,
                FetchBaseQueryError,
                object,
                FetchBaseQueryMeta
            >,
            string,
            T
        >
    ) => ({
        getBranding: builder.query<BrandingInformation, void>({
            query: () => 'branding',
            transformResponse: (response: {data: BrandingResponse}) => {
                if (response.data.available === false) {
                    return {
                        available: false,
                    };
                }

                const colours = getColours(response.data.branding_colour);
                const branding = getBranding(response.data);

                return {
                    available: true,
                    branding,
                    colours,
                };
            },
            providesTags: ['Branding'],
        }),
    }),
});

export const ThemeApi = AppApi.injectEndpoints(
    getEndpoints<typeof apiReducerPath>()
);

export const {useGetBrandingQuery} = ThemeApi;
