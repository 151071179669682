import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {useCallback} from 'react';
import {
    useCompleteSurveyMutation,
    useGetSurveysQuery,
} from 'shared/components/Surveys/store/surveyApi';

export enum SURVEYS {
    ProductVisualizer3d = '3d-product-visualiser',
}

export const useSurveys = () => {
    const {data: userProfile} = useGetUserQuery();
    const {
        data: surveys,
        isLoading,
        isFetching,
    } = useGetSurveysQuery(undefined, {skip: !userProfile?.loopbear_enabled});
    const [completeSurvey, {isLoading: completeSurveyLoading}] =
        useCompleteSurveyMutation();

    const getSurveyDetails = useCallback(
        (name: SURVEYS) => {
            if (surveys) {
                return surveys.find((survey) => survey.name == name);
            }
        },
        [surveys]
    );

    return {
        surveys,
        completeSurvey,
        getSurveyDetails,
        isLoading,
        isFetching,
        completeSurveyLoading,
    };
};
