import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
export interface Pagination {
    current_page: number;
    page_count: number;
    page_size: number;
    total_count: number;
}
export interface APIResponse<T> {
    data: T;
    pagination: Pagination;
    groupCount?: number;
}
export interface APIRequest {
    currentPage?: number;
    pageSize?: number;
}

export const AppApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
    }),
    tagTypes: [
        'Configuration',
        'UserAccounts',
        'User',
        'Material',
        'Door',
        'MaterialEdge',
        'DrawerRunner',
        'DrawerSystem',
        'EdgeFinishes',
        'Depot',
        'QFPTypes',
        'QFPProducts',
        'ProductStructure',
        'QFPProductStructure',
        'ProductDefaultRoom',
        'ProductDefault',
        'HardwareOptions',
        'HingeStyles',
        'HingeStylesRoom',
        'Cabinet',
        'Cabinets',
        'BTMBenchType',
        'BTMMaterialType',
        'BTMThickness',
        'BTMMaterials',
        'BTMEdgeProfiles',
        'BTMJoins',
        'BTMBenches',
        'BTMMaterialBeands',
        'BTMFinishes',
        'JobCost',
        'ProductRoom',
        'Job',
        'CabinetTops',
        'ProductSizes',
        'GapSizes',
        'DeliveryAddress',
        'Predictions',
        'PredictionDetails',
        'MaterialTypes',
        'MaterialFinishes',
        'MaterialBrands',
        'MaterialEdgeBrands',
        'MaterialEdgeMatch',
        'Variations',
        'JobStatus',
        'Branding',
        'Countries',
        'FingerPullStyles',
        'Appliances',
        'JobSundryItems',
        'JobSundryAttributes',
        'JobCabinetVariationDetails',
        'surveys',
        'TotalProductCount',
    ],
    reducerPath: 'appApi',
    endpoints: () => ({}),
});

export const apiReducerPath = AppApi.reducerPath;
export const apiReducer = AppApi.reducer;
export const apiMiddleware = AppApi.middleware;
