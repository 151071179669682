import {useJobContext} from 'contexts';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {getQuickProducts} from 'service';
import {PartialJob} from 'shared/types/PartialJob';
import {ConfirmationOption} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {Event} from 'shared/events/Event';
import {useLazyGetFingerPullRecessedRailsQuery} from 'components/customer/Job/store/jobApi';
import {FingerPullProduct} from 'components/customer/Job/entity/FingerPull';

export const useRecessRailsValidation = (
    showDialog: (options: ConfirmationOption) => void,
    hideDialog: () => void
): Event => {
    const {job, room} = useJobContext() as PartialJob;
    const navigate = useNavigate();
    const [fetchProducts] = useLazyGetFingerPullRecessedRailsQuery();

    const checkForRecessedRailsValidation = useCallback(async () => {
        const quickProducts = await getQuickProducts();
        const railsProducts = quickProducts.filter(
            (item) => item.isRecessedRail == 1 && item.hidden == 0
        );

        if (railsProducts.length == 0) {
            return {
                groupedCabinets: new Map<string, FingerPullProduct[]>(),
                groupedRails: new Map<string, FingerPullProduct[]>(),
                totalRailsAmountByExtColour: '',
                totalAmountByExtColour: '',
                lessRecessedHandle: false,
            };
        }

        const {data: products} = await fetchProducts({jobId: job.id});

        const groupedCabinets = new Map<string, FingerPullProduct[]>();
        const groupedRails = new Map<string, FingerPullProduct[]>();

        if (products) {
            products.forEach((product) => {
                const key = `${product.material_name}-${product.type_name}`;
                if (product.is_finger_pull) {
                    const group = groupedCabinets.get(key) || [];
                    group.push(product);
                    groupedCabinets.set(key, group);
                } else if (product.is_recessed_rail) {
                    const group = groupedRails.get(key) || [];
                    group.push(product);
                    groupedRails.set(key, group);
                }
            });
        }

        const totalProducts = new Map<string, number>();
        groupedCabinets.forEach((cabinetGroup) => {
            const keyMaterial = cabinetGroup[0].material_name;
            const totalExterior = cabinetGroup.reduce((total, item) => {
                const railsForItem =
                    (item.drawer_amount == 0 ? 1 : item.drawer_amount) *
                    item.width;
                return total + railsForItem * item.quantity;
            }, 0);

            if (totalProducts.has(keyMaterial)) {
                const previousTotal = totalProducts.get(keyMaterial) || 0;
                totalProducts.set(keyMaterial, previousTotal + totalExterior);
            } else {
                totalProducts.set(keyMaterial, totalExterior);
            }
        });

        let totalAmountByExtColour = '';
        totalProducts.forEach((totalRails, extColour) => {
            totalAmountByExtColour += `Total for ${extColour} = ${totalRails}mm <br/>`;
        });

        const totalRailProducts = new Map<string, number>();
        groupedRails.forEach((railGroup) => {
            const keyMaterial = railGroup[0].material_name;
            const totalRails = railGroup.reduce((total, item) => {
                return total + item.width * item.quantity;
            }, 0);

            if (totalRailProducts.has(keyMaterial)) {
                const previousTotalRails =
                    totalRailProducts.get(keyMaterial) || 0;
                totalRailProducts.set(
                    keyMaterial,
                    previousTotalRails + totalRails
                );
            } else {
                totalRailProducts.set(keyMaterial, totalRails);
            }
        });

        let totalRailsAmountByExtColour = '';
        totalRailProducts.forEach((totalRails, extColour) => {
            totalRailsAmountByExtColour += `Total for ${extColour} = ${totalRails}mm <br/>`;
        });

        const comparisonResult = new Map<string, boolean>();
        totalProducts.forEach((_, key) => {
            if (totalRailProducts.has(key) && totalProducts.has(key)) {
                comparisonResult.set(
                    key,
                    totalRailProducts.get(key) >= totalProducts.get(key)
                );
            } else {
                comparisonResult.set(key, false);
            }
        });

        const lessRecessedHandle = Array.from(
            comparisonResult.values()
        ).includes(false);

        return {
            groupedCabinets,
            groupedRails,
            totalRailsAmountByExtColour,
            totalAmountByExtColour,
            lessRecessedHandle,
        };
    }, [job]);

    const submitJobRailsDialog = useCallback(
        (
            groupedCabinets: Map<string, FingerPullProduct[]>,
            groupedRails: Map<string, FingerPullProduct[]>,
            totalRailsAmountByExtColour: string,
            totalAmountByExtColour: string,
            resolve: (value: boolean) => void
        ) => {
            showDialog({
                title: 'Recessed Handle Options Selected',
                message: `
            <div>
            <p>Your job contains less than the recommended amount of rails added for products which contain recessed handle options:</p>
            <table width="100%">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th width="214">Product</th>
                        <th>Material</th>
                        <th>Rail Required</th>
                    </tr>
                </thead>

                <tbody>
                ${Array.from(groupedCabinets.values())
                    .map((cabinetGroup) => {
                        const rows = cabinetGroup
                            .map((item) => {
                                const railsForItem =
                                    (item.drawer_amount == 0
                                        ? 1
                                        : item.drawer_amount) * item.width;

                                return `
                                <tr>
                                    <td>${item.room_number}</td>
                                    <td>${item.quantity} x ${
                                    item.type_name
                                }</td>
                                    <td>${item.material_name}</td>
                                    <td>${railsForItem * item.quantity}mm</td>
                                </tr>`;
                            })
                            .join('');

                        return rows;
                    })
                    .join('')}
                </tbody>
            </table>
            <p><br/>${totalAmountByExtColour}</p>
            <p>Rails added to job:</p>

            ${
                groupedRails.size === 0
                    ? '<p><strong>NO RAILS HAVE BEEN ADDED</strong></p>'
                    : `
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th width="214">Rail</th>
                                    <th>Material</th>
                                    <th>Dimensions</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${Array.from(groupedRails.values())
                                    .map((railGroup) => {
                                        const rows = railGroup
                                            .map((item) => {
                                                return `
                                                <tr>
                                                    <td>${item.room_number}</td>
                                                    <td>${item.quantity} x ${
                                                    item.type_name
                                                }</td>
                                                    <td>${
                                                        item.material_name
                                                    }</td>
                                                    <td>${item.length}x${
                                                    item.width
                                                }${
                                                    item.depth
                                                        ? `x${item.depth}`
                                                        : ''
                                                }mm</td>
                                                </tr>`;
                                            })
                                            .join('');

                                        return rows;
                                    })
                                    .join('')}
                            </tbody>
                        </table>
            `
            }
            <p><br/>${totalRailsAmountByExtColour}</p>
        </div>
            `,
                maxWidth: 650,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        alignLeft: true,
                        name: 'Back',
                        show: true,
                        variant: 'warning',
                        action: () => {
                            hideDialog();
                            resolve(false);
                        },
                    },
                    {
                        name: 'Submit Job Anyway',
                        show: true,
                        action: () => {
                            resolve(true);
                        },
                    },
                    {
                        name: 'Add More Rails',
                        show: true,
                        variant: 'primary-color',
                        action: () => {
                            resolve(false);
                            navigate(
                                `/v2/job/${job.displayId}/room/${room.id}/product?rails=true`
                            );
                        },
                    },
                ],
            });
        },
        [job, room]
    );

    const action = useCallback(async (): Promise<boolean | void> => {
        const {
            groupedCabinets,
            groupedRails,
            totalRailsAmountByExtColour,
            totalAmountByExtColour,
            lessRecessedHandle,
        } = await checkForRecessedRailsValidation();

        if (lessRecessedHandle) {
            return new Promise((resolve) => {
                submitJobRailsDialog(
                    groupedCabinets,
                    groupedRails,
                    totalRailsAmountByExtColour,
                    totalAmountByExtColour,
                    resolve
                );
            });
        } else {
            return true;
        }
    }, [checkForRecessedRailsValidation, submitJobRailsDialog]);

    return {
        action,
    };
};
