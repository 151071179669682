import {useLoopbear} from '@cabinetsbycomputer/loopbear';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';
import {useCallback, useMemo, useRef} from 'react';
import {
    SURVEYS,
    useSurveys,
} from 'shared/components/Surveys/helpers/useSurveys';

export const useSurveyButton = (
    surveyKey: SURVEYS,
    markForm = false,
    featureFlag = true
) => {
    const {data: userProfile} = useGetUserQuery();
    const {getSurveyDetails, completeSurvey, isLoading, isFetching} =
        useSurveys();
    const {showSurvey, hideSurvey} = useLoopbear();

    const surveyFormOpened = useRef(false);

    const showButton = useMemo(() => {
        const survey = getSurveyDetails(surveyKey);

        if (survey) {
            return true;
        }

        return false;
    }, [getSurveyDetails, surveyKey]);

    const showSurveyForm = useCallback(() => {
        if (!userProfile?.loopbear_enabled) {
            return;
        }

        const surveyDetails = getSurveyDetails(surveyKey);

        if (surveyDetails && featureFlag) {
            // do not show subsequent surveys if this is marked to be true
            // based on following conditions
            // 1. If customers have already seen this survey
            // 2. If customers have already seen this survey in this session
            if (markForm) {
                if (surveyFormOpened.current) {
                    return;
                }

                if (surveyDetails.is_completed) {
                    return;
                }

                // mark opened for this session
                surveyFormOpened.current = true;

                // mark opened for this user
                void completeSurvey({
                    surveyId: surveyDetails.id,
                });
            }

            showSurvey(surveyDetails.survey);
        }
    }, [
        getSurveyDetails,
        featureFlag,
        isLoading,
        isFetching,
        showSurvey,
        hideSurvey,
        markForm,
        userProfile,
    ]);

    return {
        showButton,
        showSurveyForm,
    };
};
