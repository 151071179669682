import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';

const initialState = {
    productsLoaded: false,
};

const roomProductsSlice = createSlice({
    name: 'roomProducts',
    initialState,
    reducers: {
        productsLoadedSet: (state, {payload}: PayloadAction<boolean>) => {
            state.productsLoaded = payload;
        },
    },
});

export const productsLoadedState = (store: AppState) =>
    store.roomProducts.productsLoaded;

export const {productsLoadedSet} = roomProductsSlice.actions;

export default roomProductsSlice.reducer;
