import React from 'react';
import {
    ManufacturerDisabledProperties,
    Header,
    SidebarRight,
    SidebarLeft,
    MobileMenu,
    Suspended,
} from 'shared';
import {Container} from 'react-bootstrap';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useAppContext} from 'contexts';

interface LayoutProps {
    withSidebar?: boolean;
    children: React.ReactElement;
}

const Layout = ({withSidebar, children}: LayoutProps) => {
    const {userProfile} = useAppContext();
    const visible = true;
    const isSmallDevice = isDeviceSmall();

    return (
        <>
            {withSidebar ? <SidebarLeft /> : null}

            <section
                className="mainContent"
                style={{marginBottom: visible ? '0' : '-68px'}}>
                <Header visible={visible} userProfile={userProfile} />

                <section className="component-section">
                    {userProfile &&
                    Object.keys(userProfile).length &&
                    (userProfile.isSuspended || userProfile.isBanned) ? (
                        <Suspended userProfile={userProfile} />
                    ) : (
                        <Container className="content-container">
                            {children}
                        </Container>
                    )}
                </section>

                <ManufacturerDisabledProperties />
                {isSmallDevice ? <MobileMenu /> : null}
            </section>

            {withSidebar ? <SidebarRight /> : null}
        </>
    );
};

export default Layout;
