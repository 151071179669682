import React, {useCallback, useEffect, useState} from 'react';
import {useJobContext, useNotificationContext} from 'contexts';

import {PartialJob} from 'shared/types/PartialJob';
import CBCDialog from 'shared/components/ConfirmationDialog/CBCDialog';
import Footer, {
    CloseEventType,
} from 'shared/components/ConfirmationDialog/Footer';
import JobPendingVariation from 'components/customer/JobDashboard/JobPendingVariation';
import {useRemoveProductVariationsMutation} from 'components/customer/Product/store/productApi';
import {genericMessageHandler} from 'shared/helpers';
import {useFetchBenchesData} from 'components/customer/BTM/helper/useFetchBenchesData';
import {
    invalidateVariations,
    useConfirmVariationsMutation,
    useDeleteJobCabinetVariationMutation,
    useGetJobCostQuery,
    useJobResetMutation,
    useLazyGetExtraVariationsQuery,
    useRemoveExtraVariationMutation,
} from 'components/customer/Job/store/jobApi';
import {useConfirmationDialog} from 'shared';
import {useAppDispatch} from 'store/customer';
export interface VariationStateProps {
    removedBenchIds: number[];
    setRemovedBenchIds: React.Dispatch<React.SetStateAction<number[]>>;
    removedExtraVariationIds: number[];
    setRemovedExtraVariationIds: React.Dispatch<React.SetStateAction<number[]>>;
    removedJobCabinetIds: number[];
    setRemovedJobCabinetIds: React.Dispatch<React.SetStateAction<number[]>>;
    totalVariationCost: number;
    setTotalVariationCost: React.Dispatch<React.SetStateAction<number>>;
}

const useVariationState = (): VariationStateProps => {
    const [removedBenchIds, setRemovedBenchIds] = useState<number[]>([]);
    const [removedExtraVariationIds, setRemovedExtraVariationIds] = useState<
        number[]
    >([]);
    const [removedJobCabinetIds, setRemovedJobCabinetIds] = useState<number[]>(
        []
    );
    const [totalVariationCost, setTotalVariationCost] = useState<number>(0);

    return {
        removedBenchIds,
        setRemovedBenchIds,
        removedJobCabinetIds,
        setRemovedJobCabinetIds,
        totalVariationCost,
        setTotalVariationCost,
        setRemovedExtraVariationIds,
        removedExtraVariationIds,
    };
};

export const JobVariations = () => {
    const dispatch = useAppDispatch();
    const {job, expiredJobPrice} = useJobContext() as PartialJob;
    const [removeProductVariations] = useRemoveProductVariationsMutation();
    const [jobReset] = useJobResetMutation();
    const [confirmVariations] = useConfirmVariationsMutation();
    const {notify} = useNotificationContext();
    const [displayDialog, setDisplayDialog] = useState<boolean>(true);
    const {benches} = useFetchBenchesData();
    const [getExtraVariations, {data: extraVariationList}] =
        useLazyGetExtraVariationsQuery();
    const [removeExtraVariation] = useRemoveExtraVariationMutation();
    const [removeJobCabinetVariations] = useDeleteJobCabinetVariationMutation();

    useEffect(() => {
        if (job && job.id) {
            void getExtraVariations({jobId: job.id}, true);
        }
    }, [job]);
    const variationStateProps = useVariationState();
    const {showDialog, dialog} = useConfirmationDialog();
    const {data: cost} = useGetJobCostQuery(
        {jobId: job.id},
        {
            skip: typeof job?.id === 'undefined',
        }
    );

    const displayDialogModal = useCallback(() => {
        const isAccepted = job?.accepted;

        const acceptedVariations = job?.variationsAllAccepted;

        if (!displayDialog) {
            return false;
        }

        if (
            isAccepted &&
            !acceptedVariations &&
            !expiredJobPrice &&
            (cost?.freightCost > 0 || cost?.totalVariationCost > 0)
        ) {
            return true;
        }

        return false;
    }, [
        displayDialog,
        job,
        benches,
        extraVariationList,
        expiredJobPrice,
        cost?.freightCost,
    ]);

    const toggle = useCallback(
        async (event: CloseEventType) => {
            // confirm variations
            if (event == CloseEventType.APPLY) {
                setDisplayDialog(!displayDialog);

                try {
                    await removeProductVariations({
                        data: {
                            jobCabinetIds:
                                variationStateProps.removedJobCabinetIds,
                            benchIds: variationStateProps.removedBenchIds,
                        },
                    }).unwrap();

                    if (variationStateProps.removedExtraVariationIds.length) {
                        await removeExtraVariation({
                            data: {
                                ids: variationStateProps.removedExtraVariationIds,
                            },
                        }).unwrap();
                    }

                    await confirmVariations({jobId: job?.id});
                    dispatch(invalidateVariations());

                    genericMessageHandler(
                        notify,
                        {
                            message:
                                'Variations have been successfully accepted.',
                        },
                        'success'
                    );
                } catch (error) {
                    genericMessageHandler(notify, {
                        message: 'Something went wrong',
                    });
                    throw error;
                }
            }
            // remove all variations
            if (event == CloseEventType.CANCEL) {
                showDialog({
                    title: 'Delete All Variations',
                    message: `Are you sure you want to remove all variations associated with job ${job.displayId}?`,
                    yes: async () => {
                        setDisplayDialog(!displayDialog);

                        const allBenchIds = benches
                            .filter(
                                (bench) =>
                                    !bench.isOld &&
                                    (bench.variationCost > 0 || bench.comment)
                            )
                            .map((bench) => bench.id);

                        const allExtraVariations = extraVariationList.map(
                            (extra) => extra.id
                        );

                        try {
                            await removeJobCabinetVariations({jobId: job?.id});

                            await removeProductVariations({
                                data: {
                                    jobCabinetIds: [],
                                    benchIds: allBenchIds,
                                },
                            }).unwrap();

                            if (allExtraVariations.length) {
                                await removeExtraVariation({
                                    data: {
                                        ids: allExtraVariations,
                                    },
                                }).unwrap();
                            }
                            dispatch(invalidateVariations());

                            genericMessageHandler(
                                notify,
                                {
                                    message:
                                        'Variations have been successfully removed.',
                                },
                                'success'
                            );
                        } catch (error) {
                            genericMessageHandler(notify, {
                                message: 'Something went wrong',
                            });
                            throw error;
                        }
                    },
                });
            }
            // edit
            if (event == CloseEventType.OK) {
                setDisplayDialog(!displayDialog);

                try {
                    await jobReset({jobId: job?.id, isReset: false}).unwrap();

                    genericMessageHandler(
                        notify,
                        {message: 'Job has been reactivated'},
                        'success'
                    );
                } catch (error) {
                    genericMessageHandler(notify, {
                        message: 'Something went wrong',
                    });
                    throw error;
                }
            }
        },
        [
            job,
            expiredJobPrice,
            displayDialog,
            benches,
            extraVariationList,
            variationStateProps.removedBenchIds,
            variationStateProps.removedJobCabinetIds,
            variationStateProps.removedExtraVariationIds,
        ]
    );

    return (
        <CBCDialog
            display={displayDialogModal()}
            title="Variation Request"
            footer={
                <Footer
                    okIcon="Button-Edit.svg"
                    yesButtonText="Confirm Variations"
                    okButtonText="Edit Job"
                    noButtonText={'Delete All Variations'}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClose={toggle}
                />
            }>
            {dialog}
            <JobPendingVariation {...variationStateProps} />
        </CBCDialog>
    );
};
