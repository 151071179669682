import {AppApi} from 'store/customer/api';
import {Survey} from 'shared/components/Surveys/entity/Survey';

export const SurveyApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getSurveys: builder.query<Survey[], void>({
            query: () => 'customer/surveys',
            transformResponse: (response: {surveys: Survey[]}) =>
                response.surveys,
            providesTags: [{type: 'surveys'}],
        }),
        completeSurvey: builder.mutation<boolean, {surveyId: number}>({
            query: ({surveyId}) => ({
                url: `customer/surveys/${surveyId}/complete`,
                method: 'GET',
            }),
            transformResponse: (response: {survey: boolean}) => response.survey,
            invalidatesTags: [{type: 'surveys'}],
        }),
    }),
});

export const {useGetSurveysQuery, useCompleteSurveyMutation} = SurveyApi;
