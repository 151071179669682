import {useCallback} from 'react';

export const useLoopbear = () => {
    const showSurvey = useCallback((surveyId: string) => {
        window.lb('show', {
            survey_id: surveyId,
        });
    }, []);

    const hideSurvey = useCallback(() => {
        window.lb('hide');
    }, []);

    return {showSurvey, hideSurvey};
};
