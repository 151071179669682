import {useEffect} from 'react';

interface LoopbearScriptProps {
    siteId: string;
    email: string;
    hmac: string;
    username: string;
    enabled?: boolean;
}

export const LoopbearScript = ({
    siteId,
    email,
    hmac,
    username,
    enabled = false,
}: LoopbearScriptProps): null => {
    useEffect(() => {
        if (siteId === '' || !enabled) {
            return;
        }

        window.lb =
            window.lb ||
            function (functionType: string, data: object) {
                (window.lb.q = window.lb.q || []).push([functionType, data]);
            };

        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');

        script.async = true;
        script.src = 'https://www.loopbear.com/scripts/min/lb.js';
        script.setAttribute('site-id', siteId);
        script.onload = () => {
            if (email && hmac && username) {
                window.lb('identify', {
                    user_email: email,
                    user_hash: hmac,
                });

                window.lb('variable', {
                    name: username || 'there',
                    email: email,
                });
            }
        };
        script.onerror = () => {
            // error occured loading loopbear script log error from here
        };
        head.appendChild(script);
    }, [siteId, email, hmac, username]);

    return null;
};
