import {
    LoginActionInterface,
    LoginActionResponse,
    UserAccount,
} from 'components/customer/Auth/store/authSlice';
import {AppApi} from 'store/customer/api';
import {Response} from 'service';

interface VerifyTokenInterface extends Response {
    email: string;
    accounts: UserAccount[];
}

interface LoginResponse extends Response {
    accounts?: UserAccount[];
    user_type?: number;
    domain?: string;
    redirectTo?: string;
}

interface SetPasswordRequest {
    id: string;
    token: string;
    password: string;
    confirmPassword: string;
}

const tokenVerifyFormData = (token: string) => {
    const formData = new FormData();

    formData.set('token', token);

    return formData;
};

const loginActionInterfaceToFormData = ({
    username,
    password,
    keepLoggedIn,
}: LoginActionInterface) => {
    const formData = new FormData();

    formData.set('login_email', username);
    formData.set('login_password', password);
    formData.set('keep_logged', keepLoggedIn ? '1' : '0');

    return formData;
};

const AccountApi = AppApi.injectEndpoints({
    endpoints: (build) => ({
        getAccounts: build.query<UserAccount[], void>({
            query: () => 'login/account',
            transformResponse: (response: {accounts: UserAccount[]}) =>
                response.accounts,
            providesTags: ['UserAccounts'],
        }),
        verifyToken: build.mutation<UserAccount[], string>({
            query: (token) => ({
                url: 'account/verify-password-reset-token',
                method: 'POST',
                body: tokenVerifyFormData(token),
            }),
            transformResponse: (response: VerifyTokenInterface) =>
                response.accounts,
            invalidatesTags: ['UserAccounts'],
        }),
        login: build.mutation<LoginActionResponse, LoginActionInterface>({
            query: (loginAction) => ({
                url: 'login',
                method: 'POST',
                body: loginActionInterfaceToFormData(loginAction),
            }),
            transformResponse: (response: LoginResponse, meta, arg) => {
                let accounts = response.accounts;
                const keepLoggedIn = arg.keepLoggedIn;

                if (typeof accounts === 'undefined') {
                    accounts = [
                        {
                            user_type: response.user_type,
                            domain: response.domain,
                            redirectTo: response.redirectTo,
                            id: null,
                            name: null,
                            logo: null,
                            passwordReset: null,
                            email: null,
                        },
                    ];
                }

                return {accounts, keepLoggedIn};
            },
            invalidatesTags: ['UserAccounts'],
        }),
        loginWithToken: build.mutation<LoginResponse, string>({
            query: (token) => ({
                url: `login-with-token`,
                method: 'POST',
                body: tokenVerifyFormData(token),
            }),
            invalidatesTags: ['UserAccounts'],
        }),
        verifyTokenSetPassword: build.mutation<UserAccount[], string>({
            query: (token) => ({
                url: 'account/verify-password-set-token',
                method: 'POST',
                body: tokenVerifyFormData(token),
            }),
            transformResponse: (response: VerifyTokenInterface) =>
                response.accounts,
            invalidatesTags: ['UserAccounts'],
        }),
        setPassword: build.mutation<void, SetPasswordRequest>({
            query({id, token, password, confirmPassword}) {
                const formData = new FormData();
                formData.set('id', `${id}`);
                formData.set('token', token);
                formData.set('password', password);
                formData.set('confirmPassword', confirmPassword);
                return {
                    url: `account/set-password`,
                    method: 'POST',
                    formData: true,
                    body: formData,
                };
            },
            invalidatesTags: ['UserAccounts'],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetAccountsQuery,
    useVerifyTokenMutation,
    useLoginMutation,
    useLoginWithTokenMutation,
    useVerifyTokenSetPasswordMutation,
    useSetPasswordMutation,
} = AccountApi;
