import {useEffect, useState} from 'react';
import {debounce} from 'lodash';

interface ResizeListener {
    browserSizeRatio: number;
    pixelRatio: number;
}

/**
 * Listens to resize event
 * Use this function to detect size changes or zoom changes
 *
 * @return {ResizeListener}
 */
const useResizeListener = (): ResizeListener => {
    // browser size ratio to determine browser size changes
    const [browserSizeRatio, setBrowserSizeRatio] = useState<number>();
    // pixel ratio to determine zoom changes
    const [pixelRatio, setPixelRatio] = useState<number>();

    const resizeHandler = debounce(() => {
        const sizeRatio = window.outerWidth / window.innerWidth;
        setBrowserSizeRatio(sizeRatio);
        setPixelRatio(window.devicePixelRatio);
    }, 300);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        // set initial values
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return {browserSizeRatio, pixelRatio};
};

export default useResizeListener;
