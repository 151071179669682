import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Update as UpdateInterface} from 'shared/components/Updates/entity/Update';
import {Button, Modal} from 'react-bootstrap';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import styled from 'styled-components';
import {CloseButton, Header} from 'components/customer/AdvancedMaterials';
import {Dots} from 'shared/components/Updates/Dots';
import {Button as ButtonType} from 'shared/components/Updates/entity/Button';
import {Icon} from 'shared/helpers';
import {useSaveMaterialDefaults} from 'hooks';
import {selectShowUpdateMessage} from 'store/customer/appSlice';
import {useAppSelector} from 'store/customer';

interface UpdateProps {
    update: UpdateInterface;
    setUpdate?: (update: UpdateInterface) => void;
    show: boolean;
    setShow: (showAgain: boolean, key: string) => void;
}

export const Update = ({update, show, setShow}: UpdateProps) => {
    const forcedMessage = useAppSelector(selectShowUpdateMessage);
    const [contentId, setContentId] = useState(0);
    const [showAgain, setShowAgain] = useState(false);
    const {materialDefaultSubmitHandler: updateRoom} =
        useSaveMaterialDefaults() as {
            materialDefaultSubmitHandler: (data: {
                room_assistant: number;
            }) => void;
        };

    const container = useRef<HTMLDivElement>(null);

    const content = useMemo(() => {
        if (update && update.content) {
            return update.content[Number(contentId)];
        }

        return null;
    }, [update, contentId]);

    const {showPrevious, showNext} = useMemo(() => {
        return {
            showPrevious: contentId > 0,
            showNext: contentId < update.content.length - 1,
        };
    }, [contentId, update]);

    const handleImageLoad = useCallback(
        (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            if (container.current && event.target) {
                const containerDimension =
                    container.current.getBoundingClientRect();

                if (
                    event.currentTarget.naturalWidth < containerDimension.width
                ) {
                    event.currentTarget.style.width = `${event.currentTarget.naturalWidth}px`;
                } else {
                    event.currentTarget.style.width = `100%`;
                }
            }
        },
        []
    );

    const handleButtonClick = useCallback(
        (button: ButtonType) => (event: React.MouseEvent) => {
            if (button.message) {
                event.currentTarget.innerHTML = button.message;
                event.currentTarget.setAttribute('disabled', 'disabled');
            }

            void updateRoom({room_assistant: 0});
        },
        []
    );

    const handleHide = useCallback(() => {
        setShow(showAgain, update.tutorialKey);
    }, [showAgain, setShow]);

    const handleShowAgain = useCallback(() => {
        setShowAgain((showAgain) => !showAgain);
    }, [handleHide]);

    const handleNext = useCallback(() => {
        setContentId((contentId) => contentId + 1);
    }, []);

    const handlePrevious = useCallback(() => {
        setContentId((contentId) => contentId - 1);
    }, []);

    return (
        <UpdateModal
            animation={false}
            show={show}
            backdrop="static"
            centered={true}
            onHide={handleHide}>
            <Header>
                <div data-cy="ModalTitle">{update.title}</div>
                <CloseButton
                    iconName="Button-Cancel.svg"
                    onClick={handleHide}
                    className="job-button button-light"
                    style={{color: '#8A8080'}}
                />
            </Header>

            <Body ref={container}>
                {content.image ? (
                    <ImageContainer>
                        <img src={content.image} onLoad={handleImageLoad} />
                    </ImageContainer>
                ) : null}
                <Description>
                    {parseHtmlString(content.description)}
                </Description>
            </Body>

            <Footer>
                <Left>
                    {update?.buttons && !forcedMessage
                        ? update.buttons.map((button) => {
                              return (
                                  <StyledButton
                                      key={button.id}
                                      data-cy={`${button.label}Button`}
                                      variant={button.variant}
                                      onClick={handleButtonClick(button)}>
                                      {button.label}
                                  </StyledButton>
                              );
                          })
                        : null}
                    {update?.behaviour == 'ShowOnce' ? null : (
                        <Checkbox>
                            <input
                                checked={!showAgain}
                                onChange={handleShowAgain}
                                type="checkbox"
                                id="dont-show-again-checkbox"
                            />
                            <label
                                htmlFor="dont-show-again-checkbox"
                                data-cy="dont-show-again-checkbox">
                                Don&apos;t show this again
                            </label>
                        </Checkbox>
                    )}
                </Left>

                <Center>
                    <StyledButton
                        variant="secondary"
                        disabled={!showPrevious}
                        data-cy="PreviousButton"
                        onClick={handlePrevious}>
                        <Icon
                            iconName="Button-Previous.svg"
                            className="job-button button-light"
                        />
                        <span style={{marginLeft: '15px'}}>Previous</span>
                    </StyledButton>
                    <Dots
                        total={update?.content?.length}
                        active={contentId}
                        setContentId={setContentId}
                    />
                    <StyledButton
                        variant="secondary"
                        disabled={!showNext}
                        data-cy="NextButton"
                        onClick={handleNext}>
                        <span style={{marginRight: '15px'}}>Next</span>
                        <Icon
                            iconName="Button-Next.svg"
                            className="job-button button-light"
                        />
                    </StyledButton>
                </Center>

                <Buttons>
                    {update?.content.length - 1 == contentId ? (
                        <StyledButton
                            data-cy="CloseButton"
                            variant="light"
                            onClick={handleHide}>
                            Close
                        </StyledButton>
                    ) : null}
                </Buttons>
            </Footer>
        </UpdateModal>
    );
};

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1;
`;

const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    flex: 1;
`;

const Buttons = styled.div`
    display: flex;
    gap: 5px;
    flex: 1;
    justify-content: flex-end;
`;

const Body = styled(Modal.Body)`
    padding: 0;
`;

const Description = styled.div`
    padding: 15px 15px 0;
    min-height: 200px;
`;

const ImageContainer = styled.div`
    text-align: center;
`;

const UpdateModal = styled(Modal)`
    .modal-dialog {
        max-width: 37vw;

        @media only screen and (max-width: 600px) {
            max-width: 100vw;
        }

        @media only screen and (min-width: 600px) and (orientation: portrait) {
            max-width: 80vw;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            max-width: 60vw;
        }

        @media only screen and (min-width: 1024px) and (max-width: 1280px) {
            max-width: 45vw;
        }

        .modal-content {
            border-radius: 19px;
        }
    }
`;

const Footer = styled(Modal.Footer)`
    background: rgb(var(--primary_colour));
    color: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;
const Checkbox = styled.div`
    display: flex;

    label {
        font-size: 0.85em;
        font-weight: 500;
        margin: 0;
        user-select: none;
        cursor: pointer;
    }

    input {
        margin-right: 5px;
    }
`;

const StyledButton = styled(Button)<{variant: string}>`
    ${({variant}) => {
        if (variant === 'secondary') {
            return `
                width: 100px;
                background-color: rgb(var(--secondary_colour));

                &:hover,
                &:focus {
                    background-color: rgb(var(--secondary_colour)) !important;
                }
            `;
        }
    }}

    border-radius: 15px !important;
    display: flex;
    justify-content: space-between;

    > img,
    > img:hover {
        margin: -2px -7px !important;
        padding: 0 !important;
        border: 0 !important;
        background: none;
        width: 24px;
    }
`;
