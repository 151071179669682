import {
    FingerPullProduct,
    FingerPullProductApi,
} from 'components/customer/Job/entity/FingerPull';

export const mapFingerPull = (
    fingerPull: FingerPullProductApi
): FingerPullProduct => ({
    id: parseInt(fingerPull.id),
    room_number: fingerPull.room_number,
    quantity: parseInt(fingerPull.quantity),
    drawer_amount: fingerPull.drawer_amount,
    type_name: fingerPull.type_name,
    width: parseInt(fingerPull.width),
    length: parseInt(fingerPull.length),
    depth: parseInt(fingerPull.depth),
    material_name: fingerPull.material_name,
    is_finger_pull: fingerPull.is_finger_pull,
    is_recessed_rail: fingerPull.is_recessed_rail,
});
