import React, {useEffect, useMemo} from 'react';
import {Button} from 'react-bootstrap';
import {Icon, urlPartsJob} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import useUI from 'shared/useUI';
import {useRightSidebar} from 'shared/components/Sidebar';
import {useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {
    getUI,
    UISliceInterface,
    SidebarStatus,
    SidebarBehaviour,
} from 'store/customer/UISlice';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {useGetTotalProductCountQuery} from 'components/customer/Job/store/jobApi';

interface CartToggleButtonProps {
    showOnOpen?: boolean;
}

export const CartToggleButton = ({
    showOnOpen = false,
}: CartToggleButtonProps) => {
    const {
        rightSidebarBehaviour,
        rightSidebarSoftOpen,
        rightSidebar,
        isSmallScreenLayout,
    } = useUI();
    const {toggle} = useRightSidebar();
    const theme = useTheme();

    const [jobId] = urlPartsJob();

    const isSmallDevice = isDeviceSmall();
    const ui = useAppSelector<UISliceInterface>(getUI, shallowEqual);

    const [animate, setAnimate] = React.useState(false);

    const {data: totalProductCount} = useGetTotalProductCountQuery(
        Number(jobId),
        {
            skip: !jobId,
        }
    );

    const showButton = useMemo(() => {
        return (
            !isSmallDevice &&
            jobId &&
            ((rightSidebarSoftOpen && !isSmallScreenLayout) ||
                (!rightSidebarSoftOpen &&
                    ((!showOnOpen && rightSidebar === SidebarStatus.CLOSED) ||
                        (showOnOpen && rightSidebar === SidebarStatus.OPEN)) &&
                    rightSidebarBehaviour === SidebarBehaviour.TOGGLE) ||
                (isSmallScreenLayout &&
                    ui.rightSidebar.smallScreenStatus == SidebarStatus.CLOSED &&
                    ui.rightSidebar.smallScreenBehaviour ==
                        SidebarBehaviour.AUTOHIDE))
        );
    }, [
        isSmallDevice,
        isSmallScreenLayout,
        jobId,
        rightSidebar,
        rightSidebarSoftOpen,
        rightSidebarBehaviour,
        ui,
    ]);

    useEffect(() => {
        if (totalProductCount > 0) {
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false);
            }, 1000);
        }
    }, [totalProductCount]);

    if (showButton) {
        return (
            <CartButton
                $showOnOpen={showOnOpen}
                title="Toggle cart visibility"
                onClick={toggle}
                variant="Link"
                className="sidebar-floating-button sidebar-cart-toggle-button">
                <AnimatedIcon
                    $animate={animate}
                    iconName="Cart.svg"
                    color={theme.colors.cartIconColor.main}
                />
                <ProductCount $animate={animate} data-cy="quantity-badge">
                    {totalProductCount ? totalProductCount : 0}
                </ProductCount>
                {showOnOpen ? <label>Cart</label> : ''}
            </CartButton>
        );
    }
};

const CartButton = styled(Button)<{$showOnOpen: boolean}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background: transparent !important;

    ${({$showOnOpen}) => {
        if ($showOnOpen) {
            return `
                box-shadow: none !important;
                padding-left: 0 !important;

                :hover {
                    box-shadow: none !important;
                }
            `;
        } else {
            return `
                > div {
                    left: 40px;
                }
            `;
        }
    }}

    > label {
        font-size: 0.8em;
        text-decoration: underline;
    }
`;

const Scale = keyframes`
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
`;

const Wobble = keyframes`
    0%, 20%, 53%, 80%, 100% {
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transform: translate3d(0,0,0);
    }
    40%, 43% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -10px, 0);
    }
    70% {
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -6px, 0);
    }
    90% {
        transform: translate3d(0,-2px,0);
    }
`;

const AnimatedIcon = styled(Icon)<{$animate: boolean}>`
    ${({$animate}) =>
        $animate
            ? css`
                  animation: ${Wobble} 1s ease-out 0s 1 normal forwards;
              `
            : ''}
`;

export const ProductCount = styled.div<{$animate: boolean}>`
    position: absolute;
    top: 6px;
    left: 25px;
    background-color: #ff7474;
    border-radius: 20px;
    min-width: 20px;
    color: white;
    font-weight: 500;
    height: 20px;
    line-height: 22px;
    font-size: 0.85em;
    width: fit-content;
    padding: 0 5px;
    transition: background-color 350ms ease;

    ${({$animate}) =>
        $animate
            ? css`
                  animation: ${Scale} 1s ease-out;
                  background-color: red;
              `
            : ''}
`;
