import React from 'react';
import {SURVEYS} from 'shared/components/Surveys/helpers/useSurveys';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';
import {useSurveyButton} from 'shared/components/Surveys/helpers/useSurveyButton';
import {useGetUserQuery} from 'components/customer/Auth/store/userSlice';

interface SurveyButtonProps {
    surveyKey: SURVEYS;
    style?: React.CSSProperties;
    featureFlag?: boolean;
}

export const SurveyButton = ({
    surveyKey,
    featureFlag = true,
    style,
}: SurveyButtonProps) => {
    const {data: userProfile} = useGetUserQuery();
    const {showButton, showSurveyForm} = useSurveyButton(
        surveyKey,
        false,
        featureFlag
    );

    if (!featureFlag) return null;
    if (userProfile && !userProfile.loopbear_enabled) return null;
    if (!showButton) return null;

    return (
        <FeedbackButton type="button" style={style} onClick={showSurveyForm}>
            <Icon iconName="Survey.svg" />
            Give us your Feedback
        </FeedbackButton>
    );
};

const FeedbackButton = styled.button`
    background-color: ${({theme}) => theme.colors.primary.main};
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-right-radius: 5px;
    color: white;
    font-weight: 500;
    border: 0;
    font-size: 0.75em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    > img {
        width: 20px;
    }

    :active {
        transform: translateY(1px);
    }
`;
