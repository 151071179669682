import React from 'react';
import {LoopbearScript} from '@cabinetsbycomputer/loopbear';
import {useAppContext} from 'contexts';
import {useGetSurveysQuery} from 'shared/components/Surveys/store/surveyApi';

export const Surveys = () => {
    const {userProfile} = useAppContext();
    // this is here to simply preload surveys so that form open is speedy
    useGetSurveysQuery(undefined, {skip: !userProfile?.loopbear_enabled});

    return (
        <LoopbearScript
            siteId={userProfile.loopbear_site_id}
            email={userProfile.email}
            hmac={userProfile.loopbear_user}
            username={userProfile.name}
            enabled={userProfile.loopbear_enabled}
        />
    );
};
