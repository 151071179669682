import {
    HingeStyle,
    Product as ProductInterface,
} from 'components/customer/Product/entity';
import {PartialRoom} from 'shared/types/PartialRoom';
import {CabinetTop} from 'assets';
import {SelectionInterface} from 'components/customer/Product/helpers/entity/SelectionRules';
import {store} from 'store/customer';
export const getHardwareSelection = ({
    product,
    room,
    hingeStyles,
    roomHingeStyles,
}: {
    product: ProductInterface;
    room: PartialRoom;
    hingeStyles: HingeStyle[];
    roomHingeStyles: HingeStyle[];
}): SelectionInterface[] => {
    const rules = [];
    const selectedHinge =
        product.hinge_style &&
        hingeStyles.find((hingeStyle) => hingeStyle.id == product.hinge_style);

    if (
        typeof product.cabinet_top != 'undefined' &&
        product.cabinet_top != parseInt(room.topPart) &&
        product.cabinet_top_details.is_default == '1'
    ) {
        const state = store.getState();
        const cabinetTops = state.room.cabinetTops;

        const roomTopSelected =
            cabinetTops &&
            cabinetTops.find((top) => top.id == parseInt(room.topPart));
        rules.push({
            key: 'top_part',
            name: 'Cabinet Top',
            is_saved: room.customerOptions && room.customerOptions.isTopPartSet,
            set_key: 'isTopPartSet',
            room_data:
                parseInt(room.topPart) == -1 ? 'None' : roomTopSelected.name,
            product_data: product.cabinet_top_details.name,
            value: product.cabinet_top,
            image: product.cabinet_top_details.name
                ? `/${product.cabinet_top_details.image}`
                : String(CabinetTop),
            apply_to_room: true,
        });
    }

    const getRoomHingeStyleName = (room: PartialRoom): string => {
        const roomHingeStyle = roomHingeStyles?.find(
            (hingeStyle) => hingeStyle.id == Number(room.hingeStyle)
        );

        return roomHingeStyle?.name || room.hingeStyleName || '';
    };
    const roomHingeStyleName = getRoomHingeStyleName(room);

    if (
        selectedHinge &&
        selectedHinge.roomAvailability &&
        selectedHinge.name != roomHingeStyleName
    ) {
        rules.push({
            key: 'hinge_style',
            name: 'Hinge Style',
            is_saved:
                room.customerOptions && room.customerOptions.isHingeStyleSet,
            set_key: 'isHingeStyleSet',
            room_data: roomHingeStyleName,
            product_data: selectedHinge.name,
            value: selectedHinge.name,
            image: `/${selectedHinge.image}`,
            apply_to_room: true,
        });
    }

    return rules;
};
