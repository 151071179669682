import {useState, useEffect} from 'react';
import {
    Material,
    MaterialEdge,
    Door,
} from 'components/customer/Materials/entity';
import {isString} from 'lodash';
import {APIRequest} from 'store/customer/api';
import {Page} from 'store/customer/entity/Page';

export const useDebounce = <T>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

export const stripSizeFromString = (keywords: string) => {
    const regex = new RegExp(/\s?\d+mm/gm);
    const results = keywords.match(regex);

    if (results == null) return keywords;

    let newKeywords = keywords;
    results.forEach((result) => {
        const digit = result.replace(/\D/g, '');

        newKeywords = newKeywords.replace(result.trim(), `${digit}`);
    });

    // stripping html tags
    newKeywords = newKeywords.replace(/(<([^>]+)>)/gi, '');

    // stripping hyphens characters as well
    newKeywords = newKeywords.replace(/-/g, '');

    return newKeywords;
};

export const flattenByDoorFilter = (materials: Material[]): Material[] => {
    const updatedMaterials: Material[] = [];

    materials.forEach((material) => {
        const doesParamsMatch = updatedMaterials.find(
            (updatedMaterial) =>
                updatedMaterial.name == material.name &&
                updatedMaterial.type.id == material.type.id &&
                updatedMaterial.brand.id == material.brand.id &&
                updatedMaterial.finish == material.finish &&
                updatedMaterial.substrate == material.substrate &&
                updatedMaterial.thickness == material.thickness &&
                !updatedMaterial.door_filters
                    .map((doorMaterial) => doorMaterial.door_filter)
                    .includes(material.door_filter)
        );

        if (doesParamsMatch) {
            doesParamsMatch.door_filters.push(material);
        } else {
            updatedMaterials.push(material);
        }
    });

    return updatedMaterials;
};

export const transformDoorFilters = (material: Material) => ({
    ...material,
    door_filters: [material],
});

export const transformDisplayName = (material: Material) => {
    let displayName = `${material.name} <em>${material.finish}</em> - ${material.thickness}mm ${material.brand.name}`;
    if ('substrate' in material) {
        displayName += ` ${material.substrate}`;
    }

    return {
        ...material,
        displayName,
    };
};

export const transformDisplayNameEdge = (edge: MaterialEdge) => {
    return {
        ...edge,
        displayName: `${edge.thickness > 0 ? `${edge.thickness}mm` : ''} ${
            edge.name
        } ${edge.brand.name} <em>${edge.finish}</em>`,
    };
};

export const transformMaterialImage = <T extends Material | MaterialEdge>(
    material: T
) => {
    return {
        ...material,
        image: `/uploads/gocabinet_materials/${material.image}`,
    };
};

export const transformDoorImage = (door: Door) => {
    if (door.image) {
        return {
            ...door,
            ...{
                image: {
                    ...door.image,
                    name: `/uploads/images/Door - Drawer Face/${door.image.name}`,
                },
            },
        };
    } else if (door.name == 'Non Supply') {
        return {
            ...door,
            ...{
                image: {
                    name: '/uploads/gocabinet_materials/Non Supply/Na.jpg',
                },
            },
        };
    }

    return door;
};

export const transformEdgeFinishValues = (door: Door) => {
    return {
        ...door,
        is_default_edge_finish: door.is_default_edge_finish == 1,
        is_locked_edge_finish: door.is_locked_edge_finish == 1,
        default_edge_finish_bottom: parseInt(door.default_edge_finish_bottom),
        default_edge_finish_left: parseInt(door.default_edge_finish_left),
        default_edge_finish_right: parseInt(door.default_edge_finish_right),
        default_edge_finish_top: parseInt(door.default_edge_finish_top),
        default_edge_finish_join: parseInt(door.default_edge_finish_join),
    };
};

export const flattenChanges = (door: Door) => {
    if (door.changes) {
        return {
            ...door,
            is_default_edge_finish:
                door.changes[0] && door.changes[0].is_default_edge_finish,
            is_locked_edge_finish:
                door.changes[0] && door.changes[0].is_locked_edge_finish,
            default_edge_finish_bottom:
                door.changes[0] && door.changes[0].default_edge_finish_bottom,
            default_edge_finish_left:
                door.changes[0] && door.changes[0].default_edge_finish_left,
            default_edge_finish_right:
                door.changes[0] && door.changes[0].default_edge_finish_right,
            default_edge_finish_top:
                door.changes[0] && door.changes[0].default_edge_finish_top,
            default_edge_finish_join:
                door.changes[0] && door.changes[0].default_edge_finish_join,
        };
    }

    return door;
};

export const filterHiddenDoor = (door: Door) => {
    if (door.changes && door.changes.length > 0) {
        return door.changes.map((change) => !change.is_hidden).every(Boolean);
    }

    return true;
};

// TODO: may be remove this later if custom colour data is passed from backend
export const transformCustomColour = (material: Material) => ({
    ...material,
    is_custom_colour: material.name.toLowerCase().indexOf('custom colour') > -1,
});

const highlightTextNode = (text: string) => (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
        const textIndex = node.textContent
            .toLowerCase()
            .indexOf(text.toLowerCase());
        const endIndex = textIndex + text.length;

        if (textIndex > -1 && endIndex <= node.textContent.length) {
            node.splitText(endIndex);

            const matchingNode = node.splitText(textIndex);
            const newElement = document.createElement('strong');
            newElement.textContent = matchingNode.textContent;

            node.parentNode.replaceChild(newElement, matchingNode);
        }
    } else {
        node.childNodes.forEach(highlightTextNode(text));
    }
};

export const highlightWord = (htmlString: string, text: string) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlString, 'text/html');

    htmlDoc.body.childNodes.forEach(highlightTextNode(text));

    return htmlDoc.body.innerHTML;
};

export const transformHighlightSearch =
    <T, K extends keyof T>(keywords: string, sourceKey: K, key: K) =>
    (data: T): T => {
        const keywordsArray = keywords.split(' ');

        const originalText = data.hasOwnProperty(sourceKey) && data[sourceKey];
        if (isString(originalText)) {
            let highlightedText = originalText as string;
            keywordsArray.forEach((keyword) => {
                highlightedText = highlightWord(highlightedText, keyword);
            });

            return {
                ...data,
                [key]: highlightedText,
            };
        }

        return data;
    };
export interface MaterialEdgeSearchRequest extends APIRequest {
    name?: string;
    type?: number;
    colour?: number;
    manufacturerId?: number;
    cabinetType?: number;
    doorFilter?: string;
    brand?: string;
}

export const searchEdges = ({
    currentPage = 1,
    pageSize = 1000,
    ...request
}: MaterialEdgeSearchRequest) => {
    const criteria: string[] = [];

    if (request.manufacturerId) {
        criteria.push(
            `((changes.manufacturer_id:equals:${request.manufacturerId}))`
        );
    }

    if (request.colour) {
        criteria.push(`((id:equals:${request.colour}))`);
    } else {
        if (request.type) {
            criteria.push(`((material_type_id:equals:${request.type}))`);
        }

        if (request.name) {
            criteria.push(`((name:equals:${request.name}))`);
        }

        if (request.doorFilter) {
            criteria.push(`((door_filter:equals:${request.doorFilter}))`);
        }

        if (request.brand) {
            criteria.push(`((brand.name:equals:${request.brand}))`);
        }

        if (request.cabinetType) {
            criteria.push(`((cabinet.type:equals:${request.cabinetType}))`);
        }
    }

    criteria.push('((changes.is_hidden:equals:0))');

    const url = `product/edges?filter_groups=${criteria.join(
        ' AND '
    )}&related_includes=brand,material_type&current_page=${currentPage}&page_size=${pageSize}&sort_orders=name,material_type.name,brand.name,finish,thickness`;

    return url;
};

const getTutorialKey = (page: Page): string => {
    switch (page) {
        case Page.PRODUCT:
            return 'material_search_tutorial_product';

        case Page.ROOM:
            return 'material_search_tutorial_room';
    }
};

export const setMaterialTutorialPreference = (page: Page, show: boolean) => {
    if (typeof localStorage != 'undefined') {
        const tutorialKey = getTutorialKey(page);

        localStorage.setItem(tutorialKey, show ? '1' : '0');
    }
};

export const filterDuplicates = (materials: Material[]) => {
    const uniqueMaterials: Material[] = [];

    // NOTE: Sorting by id so we take smallest id and ignore the rest
    materials
        .sort((a, b) => a.id - b.id)
        .forEach((material) => {
            if (
                !uniqueMaterials.find(
                    (uniqueMaterial) =>
                        uniqueMaterial.name == material.name &&
                        uniqueMaterial.type.id == material.type.id &&
                        uniqueMaterial.brand.id == material.brand.id &&
                        uniqueMaterial.finish == material.finish &&
                        uniqueMaterial.substrate == material.substrate &&
                        uniqueMaterial.thickness == material.thickness
                )
            ) {
                uniqueMaterials.push(material);
            }
        });

    // NOTE: ReSorting by name before sending it back
    return uniqueMaterials.sort((a, b) => a.name.localeCompare(b.name));
};
