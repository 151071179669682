import React, {useMemo} from 'react';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {genericMessageHandler, formatPrice} from 'shared/helpers';
import {removeItem, deleteBenchtop} from 'service';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useConfirmationDialog} from 'shared';
import {useAppDispatch} from 'store/customer';
import {
    invalidateJob,
    invalidateJobCost,
    invalidateTotalProductCount,
} from 'components/customer/Job/store/jobApi';
import {JOB_STATUS} from 'components/customer/Dashboard/LastJobButton';
import {useGetJobSundryId} from 'components/customer/Hardware/helper/useGetJobSundryId';

export const useSundries = () => {
    const {jobSundryId} = useGetJobSundryId();
    const {userProfile} = useAppContext();
    const {notify} = useNotificationContext();
    const {job, room, isPageDirty, setIsPageDirty, actionButtonsEnabled} =
        useJobContext();
    const {showDialog, dialog} = useConfirmationDialog();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {jobId} = useParams();

    const [searchParams] = useSearchParams();

    const hardware_store_url = `/v2/job/${job.displayId}/hardware`;

    const removeItemFromJob = (item, event) => {
        const button = event.target.parentElement;
        showDialog({
            title: 'Delete Item',
            message: 'Are you sure you want to delete this item from the job?',
            yes: async () => {
                try {
                    button.disabled = true;
                    await removeItem(item.id);

                    setIsPageDirty([]);

                    button.disabled = false;
                    genericMessageHandler(
                        notify,
                        {message: 'Sundry item removed the from job'},
                        'success'
                    );

                    dispatch(invalidateJob());
                    dispatch(invalidateJobCost());
                    dispatch(invalidateTotalProductCount());

                    if (
                        searchParams.has('sundry') &&
                        searchParams.has('jobSundry') &&
                        searchParams.get('jobSundry') != ''
                    ) {
                        const sundryId = searchParams.get('sundry');

                        if (sundryId == item.sundryItemId) {
                            if (room && room.id) {
                                navigate(
                                    `/v2/job/${job.displayId}/room/${room.id}/dashboard`
                                );
                            } else {
                                navigate(`/v2/job/${job.displayId}/dashboard`);
                            }
                        }
                    }
                } catch (e) {
                    button.disabled = false;
                    genericMessageHandler(notify, e);
                }
            },
            buttons: [
                {
                    name: 'Cancel',
                    variant: 'warning',
                },
            ],
        });
    };

    const editItemFromJob = (row) => {
        navigate(
            `/v2/job/${jobId}/hardware?sundry=${row.sundryItemId}&category=${row.sundryCategoryId}&jobSundry=${row.id}`
        );
    };

    const strongFormatter = (value, row) => {
        if (row.id == Number(jobSundryId)) {
            return (
                <strong>
                    {row.quantity} x {value}
                </strong>
            );
        } else {
            return `${row.quantity} x ${value}`;
        }
    };
    const noVariationHardware = (variations) => {
        if (variations > 0) {
            return <>No Sundries.</>;
        } else {
            return (
                <>
                    No Sundries.{' '}
                    {job.displayId && (
                        <span
                            style={{color: 'red', cursor: 'pointer'}}
                            onClick={() => navigate(hardware_store_url)}>
                            Click <strong>{userProfile.sundryMenuText} </strong>
                            to add one
                        </span>
                    )}
                </>
            );
        }
    };

    const tableConfig = useMemo(() => {
        const tableConfig = {
            fields: [
                {
                    fieldName: 'sundryItemName',
                    title: 'Current ' + userProfile.sundryMenuText,
                    format: strongFormatter,
                },
                userProfile.show_pricing
                    ? {
                          fieldName: 'totalCalculatedPrice',
                          title: 'Cost',
                          width: 65,
                          format: function hardwareFormatPrice(value, row) {
                              return (
                                  <div
                                      style={
                                          job.status ==
                                          JOB_STATUS.JOB_PENDING_CONFIRMATION
                                              ? {textAlign: 'center'}
                                              : {}
                                      }>
                                      {jobSundryId == row.id ? (
                                          <strong>
                                              {' '}
                                              {formatPrice(value, row)}{' '}
                                          </strong>
                                      ) : (
                                          formatPrice(value, row)
                                      )}
                                  </div>
                              );
                          },
                      }
                    : {},
            ],
            data:
                (job.hasOwnProperty('sundryItems') &&
                    job.sundryItems.map((item) => item.attributes)) ||
                [],
            noRecordsText: <>{noVariationHardware(job.variationsConfirmed)}</>,
        };

        if (job.status != JOB_STATUS.JOB_PENDING_CONFIRMATION) {
            tableConfig.options = {
                title: '',
                width: 75,
                buttons: [
                    {
                        iconName: 'Options-Edit.svg',
                        title: 'Edit Product',
                        action: editItemFromJob,
                    },
                    {
                        iconName: 'Options-Delete.svg',
                        title: 'Delete Product',
                        action: removeItemFromJob,
                        confirmChanges: isPageDirty.length > 0,
                    },
                ],
            };
        }

        if (!actionButtonsEnabled) {
            tableConfig.options = undefined;
        }

        return tableConfig;
    }, [job, userProfile, isPageDirty, actionButtonsEnabled, jobSundryId]);

    return {
        tableConfig,
        dialog,
    };
};

export const useBenchtopTableConfig = () => {
    const dispatch = useAppDispatch();
    const {notify} = useNotificationContext();
    const {job, room, isPageDirty, setIsPageDirty, actionButtonsEnabled} =
        useJobContext();
    const {showDialog, dialog} = useConfirmationDialog();
    const navigate = useNavigate();
    const {userProfile} = useAppContext();
    const addRoomUrl = `/v2/job/${job.displayId}/room`;
    const addBenchtopUrl = `/v2/job/${job.displayId}/room/${room.id}/benchtop-designer`;

    const removeBenchtop = (bench, event) => {
        const button = event.target.parentElement;
        showDialog({
            title: 'Delete benchtop',
            message:
                'Are you sure you want to delete this benchtop from the job?',
            yes: async () => {
                try {
                    button.disabled = true;
                    await deleteBenchtop(
                        job.id,
                        room.id,
                        bench.externalBenchId
                    );

                    setIsPageDirty([]);

                    button.disabled = false;
                    genericMessageHandler(
                        notify,
                        {message: 'Benchtop removed from the job'},
                        'success'
                    );
                    dispatch(invalidateJobCost());
                    dispatch(invalidateJob());
                } catch (e) {
                    button.disabled = false;
                    genericMessageHandler(notify, e);
                }
            },
        });
    };

    const editBenchtop = (row) => {
        navigate(
            `/v2/job/${parseInt(row.jobId) + 10000}/room/${
                row.roomId
            }/benchtop-designer?benchType=${row.shape}&benchId=${
                row.externalBenchId
            }`
        );
    };

    const noRoomAndVariationBenchtop = (room, variations) => {
        if (!room && variations === 0) {
            return (
                <>
                    Please click{' '}
                    <span
                        style={{color: 'red', cursor: 'pointer'}}
                        onClick={() => navigate(addRoomUrl)}>
                        <strong>Add A Room </strong>
                    </span>{' '}
                    before adding benchtops.
                </>
            );
        } else if (room && variations > 0) {
            return <>No Benchtops.</>;
        } else {
            return (
                <>
                    No Benchtops.{' '}
                    {job.displayId && (
                        <span
                            style={{color: 'red', cursor: 'pointer'}}
                            onClick={() => navigate(addBenchtopUrl)}>
                            <strong>Click Add BenchTops</strong> to add one.
                        </span>
                    )}
                </>
            );
        }
    };

    const tableConfig = useMemo(() => {
        const tableConfig = {
            fields: [
                {
                    fieldName: 'productNumber',
                    title: '#',
                    format: function formatProductNumber(productNumber) {
                        return <strong>{productNumber}</strong>;
                    },
                },
                {
                    fieldName: 'shape',
                    title: 'Current Benchtops',
                    format: (name) => `${name} shape`,
                },
                {fieldName: 'dimensionString', title: 'Dimensions', width: 80},
                userProfile.show_pricing
                    ? {
                          fieldName: 'cost',
                          title: 'Cost',
                          width: 65,
                          format: function benchTopFormatPrice(value, row) {
                              return (
                                  <div
                                      style={
                                          job.status ==
                                          JOB_STATUS.JOB_PENDING_CONFIRMATION
                                              ? {textAlign: 'center'}
                                              : {}
                                      }>
                                      {formatPrice(value, row)}
                                  </div>
                              );
                          },
                      }
                    : {},
            ],
            data:
                (room.hasOwnProperty('jobBenchtops') && room.jobBenchtops) ||
                [],
            noRecordsText: (
                <>
                    {noRoomAndVariationBenchtop(
                        room.id,
                        job.variationsConfirmed
                    )}
                </>
            ),
        };

        if (job.status != 5) {
            tableConfig.options = {
                title: '',
                width: 75,
                buttons: [
                    userProfile.isBTDAvailable
                        ? {
                              iconName: 'Options-Edit.svg',
                              title: 'Edit Benchtop',
                              action: editBenchtop,
                          }
                        : {},
                    {
                        iconName: 'Options-Delete.svg',
                        title: 'Delete Benchtop',
                        action: removeBenchtop,
                        confirmChanges: isPageDirty.length > 0,
                    },
                ],
            };
        }

        if (!actionButtonsEnabled) {
            tableConfig.options = undefined;
        }

        return tableConfig;
    }, [job, room, isPageDirty, actionButtonsEnabled]);

    return {
        tableConfig,
        dialog,
    };
};
