import {useEffect, useRef} from 'react';

type FocusIndexType = number | null;

export default (
    scrollIndex: FocusIndexType,
    dataLength: number,
    options: ScrollIntoViewOptions = {}
) => {
    const elementsRef = useRef<HTMLDivElement[]>([]);

    const scrollToIndex = (index: number) => {
        if (elementsRef.current?.at(index)) {
            elementsRef.current?.at(index)?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                ...options,
            });
        }
    };

    const setRef = (element: HTMLDivElement, index: number) => {
        elementsRef.current[Number(index)] = element;
    };

    useEffect(() => {
        if (
            elementsRef &&
            scrollIndex != null &&
            dataLength > 0 &&
            elementsRef.current.length === dataLength
        ) {
            scrollToIndex(scrollIndex);
        }
    }, [dataLength, elementsRef, scrollIndex]);

    return {
        elementsRef,
        setRef,
    };
};
