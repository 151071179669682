import {
    Door,
    Material,
    MaterialEdge,
    MaterialType,
} from 'components/customer/Materials/entity';
import {Page} from 'store/customer/entity/Page';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';
import {cloneDeep} from 'lodash';
export interface MaterialInterface {
    materials: Material[];
    material?: Material;
    materialPageNumber: number;
    edgeMaterials: MaterialEdge[];
    edgeMaterial?: MaterialEdge;
    edgeMaterialPageNumber: number;
    carcases: Material[];
    carcase?: Material;
    carcasePageNumber: number;
    edgeCarcases: MaterialEdge[];
    edgeCarcase?: MaterialEdge;
    edgeCarcasePageNumber: number;
    doors: Door[];
    door?: Door;
    doorPageNumber: number;
    pendingDoor?: Door;
    hasDoor: boolean;
    defaultLoadedExterior: boolean;
    defaultLoadedCarcase: boolean;
    deleted: boolean;
    searchExteriorEdge: boolean;
    searchCarcaseEdge: boolean;
    searchDoor: boolean;
    searchExterior: boolean;
    searchCarcase: boolean;
    loadingExterior: boolean;
    loadingCarcase: boolean;
}

interface MaterialStateInterface {
    materials: MaterialInterface[];
    deletedMaterials: number[];
    activeIndex: number;
}

type StateUpdaterInterface<T> = {
    materialState: MaterialStateInterface;
    state: MaterialInterface;
    payload: T;
};
type Meta = {index?: number};

type SelectorType<T> = (material: MaterialInterface, type?: MaterialType) => T;

type StateUpdater<T> = (stateUpdaterProps: StateUpdaterInterface<T>) => void;

const genericReducer = <T>(updater: StateUpdater<T>) => ({
    reducer: (
        state: MaterialStateInterface,
        {payload, meta}: PayloadAction<T, string, Meta>
    ) => {
        const currentIndex =
            meta && meta.hasOwnProperty('index') && meta.index > -1
                ? meta.index
                : state.activeIndex;
        const material = state.materials[Number(currentIndex)];

        updater({materialState: state, state: material, payload});
    },
    prepare: (payload: T, index?: number) => ({
        payload,
        meta: {index},
    }),
});

const emptyMaterial: MaterialInterface = {
    hasDoor: true,
    materials: [],
    edgeMaterials: [],
    carcases: [],
    edgeCarcases: [],
    doors: [],
    materialPageNumber: 1,
    edgeMaterialPageNumber: 1,
    carcasePageNumber: 1,
    edgeCarcasePageNumber: 1,
    doorPageNumber: 1,
    defaultLoadedExterior: false,
    defaultLoadedCarcase: false,
    deleted: false,
    searchExteriorEdge: false,
    searchCarcaseEdge: false,
    searchDoor: false,
    searchExterior: false,
    searchCarcase: false,
    loadingExterior: false,
    loadingCarcase: false,
};

const initialState: MaterialStateInterface = {
    materials: [emptyMaterial],
    deletedMaterials: [],
    activeIndex: 0,
};

const materialStateSlice = createSlice({
    name: 'material',
    initialState,
    reducers: {
        activeSet: (state, {payload}: PayloadAction<number>) => {
            state.activeIndex = payload;
        },
        searchDoorSet: genericReducer<boolean>(({state, payload}) => {
            if (state) state.searchDoor = payload;
        }),
        searchExteriorSet: genericReducer<boolean>(({state, payload}) => {
            if (state) state.searchExterior = payload;
        }),
        searchCarcaseSet: genericReducer<boolean>(({state, payload}) => {
            if (state) state.searchCarcase = payload;
        }),
        searchExteriorEdgeSet: genericReducer<boolean>(({state, payload}) => {
            if (state) state.searchExteriorEdge = payload;
        }),
        searchCarcaseEdgeSet: genericReducer<boolean>(({state, payload}) => {
            if (state) state.searchCarcaseEdge = payload;
        }),
        hasDoorSet: genericReducer<boolean>(({state, payload}) => {
            if (state) state.hasDoor = payload;
        }),
        materialSet: genericReducer<Material>(({state, payload}) => {
            if (state) state.material = payload;
        }),
        materialsSet: genericReducer<Material[]>(({state, payload}) => {
            if (state) state.materials = payload;
        }),
        materialsAdd: genericReducer<Material[]>(({state, payload}) => {
            if (state)
                state.materials.splice(state.materials.length, 0, ...payload);
        }),
        materialPageSet: genericReducer<number>(({state, payload}) => {
            if (state) state.materialPageNumber = payload;
        }),
        edgeMaterialSet: genericReducer<MaterialEdge>(({state, payload}) => {
            if (state) state.edgeMaterial = payload;
        }),
        edgeMaterialsSet: genericReducer<MaterialEdge[]>(({state, payload}) => {
            if (state) state.edgeMaterials = payload;
        }),
        edgeMaterialsAdd: genericReducer<MaterialEdge[]>(({state, payload}) => {
            if (state)
                state.edgeMaterials.splice(
                    state.edgeMaterials.length,
                    0,
                    ...payload
                );
        }),
        edgeMaterialPageSet: genericReducer<number>(({state, payload}) => {
            if (state) state.edgeMaterialPageNumber = payload;
        }),
        carcaseSet: genericReducer<Material>(({state, payload}) => {
            if (state) state.carcase = payload;
        }),
        carcasesSet: genericReducer<Material[]>(({state, payload}) => {
            if (state) state.carcases = payload;
        }),
        carcasesAdd: genericReducer<Material[]>(({state, payload}) => {
            if (state)
                state.carcases.splice(state.carcases.length, 0, ...payload);
        }),
        carcasePageSet: genericReducer<number>(({state, payload}) => {
            if (state) state.carcasePageNumber = payload;
        }),
        edgeCarcaseSet: genericReducer<MaterialEdge>(({state, payload}) => {
            if (state) state.edgeCarcase = payload;
        }),
        edgeCarcasesSet: genericReducer<MaterialEdge[]>(({state, payload}) => {
            if (state) state.edgeCarcases = payload;
        }),
        edgeCarcasesAdd: genericReducer<MaterialEdge[]>(({state, payload}) => {
            if (state)
                state.edgeCarcases.splice(
                    state.edgeCarcases.length,
                    0,
                    ...payload
                );
        }),
        edgeCarcasePageSet: genericReducer<number>(({state, payload}) => {
            if (state) state.edgeCarcasePageNumber = payload;
        }),
        doorSet: genericReducer<Door>(({state, payload}) => {
            if (state) state.door = payload;
        }),
        doorsSet: genericReducer<Door[]>(({state, payload}) => {
            if (state) state.doors = payload;
        }),
        doorsAdd: genericReducer<Door[]>(({state, payload}) => {
            if (state) state.doors.splice(state.doors.length, 0, ...payload);
        }),
        doorPageSet: genericReducer<number>(({state, payload}) => {
            if (state) state.doorPageNumber = payload;
        }),
        pendingDoorSet: genericReducer<Door>(({state, payload}) => {
            if (state) state.pendingDoor = payload;
        }),
        pendingDoorCleared: genericReducer(({state}) => {
            if (state) state.pendingDoor = null;
        }),
        clearMaterials: genericReducer(({state}) => {
            if (state) {
                state.materials = [];
                state.doors = [];
                state.edgeMaterials = [];
                state.materialPageNumber = 1;
                state.doorPageNumber = 1;
                state.edgeMaterialPageNumber = 1;
                state.searchExteriorEdge = false;
                state.searchCarcaseEdge = false;
                state.searchDoor = false;
                state.loadingExterior = false;
                state.loadingCarcase = false;
            }
        }),
        defaultLoadedExteriorSet: genericReducer<boolean>(
            ({state, payload}) => {
                if (state) state.defaultLoadedExterior = payload;
            }
        ),
        defaultLoadedCarcaseSet: genericReducer<boolean>(({state, payload}) => {
            if (state) state.defaultLoadedCarcase = payload;
        }),
        deleteMaterials: genericReducer<number>(
            ({materialState, state, payload: index}) => {
                if (state) {
                    state.deleted = true;

                    materialState.deletedMaterials.push(index);
                }
            }
        ),
        initMaterials: (state, {payload: amount}: PayloadAction<number>) => {
            if (amount > 0)
                state.materials = Array.from(
                    {length: amount},
                    () => emptyMaterial
                );
        },
        materialsAppend: (state) => {
            let material;
            if (state.materials.length >= 1) {
                material = cloneDeep(
                    state.materials[state.materials.length - 1]
                );
            }

            material.deleted = false;
            state.materials.push(material);
            state.activeIndex = state.materials.length - 1;
        },
        materialsCopy: genericReducer<number>(
            ({materialState, state, payload: length}) => {
                if (state) {
                    const newMaterials = Array.from({length}, () => ({
                        ...cloneDeep(state),
                        deleted: false,
                    }));

                    materialState.materials = [
                        ...materialState.materials,
                        ...newMaterials,
                    ];
                    materialState.activeIndex =
                        materialState.materials.length - 1;
                }
            }
        ),
        allDataCleared: (state) => {
            state.materials = [emptyMaterial];
            state.deletedMaterials = [];
            state.activeIndex = 0;
        },
        undoDeleteMaterials: (state) => {
            if (state.deletedMaterials.length > 0) {
                const index = state.deletedMaterials.pop();

                const materialInfo = state.materials.slice(index, index + 1);

                if (materialInfo.length > 0) {
                    state.materials.push(cloneDeep(materialInfo[0]));
                }
            }
        },
    },
});

const selectAllMaterials = (store: AppState) => store.materials;
export const selectMaterialByIndex = (store: AppState, index: number) =>
    store.materials.materials[Number(index)];

const genericSelector = <T>(selector: SelectorType<T>) => {
    return (
        materials: MaterialStateInterface,
        type?: MaterialType,
        index?: number
    ) => {
        const currentIndex =
            typeof index !== 'undefined' ? index : materials.activeIndex;
        const materialState = materials.materials[Number(currentIndex)];

        return selector(materialState, type);
    };
};

export const createSelectorMaterial = <T>(selector: SelectorType<T>) =>
    createSelector(
        [
            selectAllMaterials,
            (state: AppState, type?: MaterialType) => type,
            (state: AppState, type?: MaterialType, index?: number) => index,
        ],
        genericSelector(selector)
    );

export const selectDefaultLoaded = (
    store: AppState,
    materialType: MaterialType,
    index = 0
) => {
    if (store.application.page != Page.QFP) return true;

    const material = store.materials.materials[Number(index)];
    if (material) {
        if (materialType == MaterialType.EXTERIOR) {
            return material.defaultLoadedExterior;
        }
        return material.defaultLoadedCarcase;
    }

    return false;
};

export const selectSearch = createSelectorMaterial((state, type) => {
    if (type == MaterialType.EXTERIOR) {
        return state.searchExteriorEdge;
    }

    return state.searchCarcaseEdge;
});

export const materialsLoading = createSelectorMaterial((state, type) => {
    if (type == MaterialType.EXTERIOR) {
        return (
            state &&
            (state.searchExterior ||
                state.searchDoor ||
                state.searchExteriorEdge)
        );
    }

    return state && (state.searchCarcase || state.searchCarcaseEdge);
});

export const selectIsValid = createSelectorMaterial((state) => {
    return (
        !state?.material?.is_hidden &&
        !state?.edgeMaterial?.is_hidden &&
        !state?.carcase?.is_hidden &&
        !state?.edgeCarcase?.is_hidden &&
        !state?.door?.is_hidden
    );
});

// TODO: Replace this with a flag on the material type. See CO-1828
const NON_SUPPLY_MATERIAL_TYPE = 7;

export const selectIsNonSupply = createSelectorMaterial(
    (state) => state.material?.type_id == NON_SUPPLY_MATERIAL_TYPE
);

export const selectIsNonSupplyCarcase = createSelectorMaterial(
    (state) => state.carcase?.type_id == NON_SUPPLY_MATERIAL_TYPE
);

export const {
    activeSet,
    hasDoorSet,
    materialSet,
    materialsSet,
    materialsAdd,
    materialPageSet,
    edgeMaterialSet,
    edgeMaterialsSet,
    edgeMaterialsAdd,
    edgeMaterialPageSet,
    carcaseSet,
    carcasesSet,
    carcasesAdd,
    carcasePageSet,
    edgeCarcaseSet,
    edgeCarcasesSet,
    edgeCarcasesAdd,
    edgeCarcasePageSet,
    doorSet,
    doorsSet,
    doorsAdd,
    doorPageSet,
    pendingDoorSet,
    pendingDoorCleared,
    allDataCleared,
    initMaterials,
    deleteMaterials,
    undoDeleteMaterials,
    materialsAppend,
    materialsCopy,
    clearMaterials,
    defaultLoadedExteriorSet,
    defaultLoadedCarcaseSet,
    searchExteriorEdgeSet,
    searchCarcaseEdgeSet,
    searchDoorSet,
    searchExteriorSet,
    searchCarcaseSet,
} = materialStateSlice.actions;

export default materialStateSlice.reducer;
